import React from "react";
import Retool from "react-retool";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: 0 };
  }

  componentDidMount() {
    document.title = process.env.REACT_APP_TITLE;
  }

  buttonClicked(event) {
    this.setState({ value: this.state.value + 1 });
  }

  render() {
    return (
      <div style={{ height: "100vh", overflow: "hidden" }} className="App">
        <div
          style={{ height: "100vh", overflow: "hidden" }}
          className="retool-box"
        >
          <Retool
            style={{ height: "100vh", width: "100%", overflow: "hidden" }} // Allow internal scrolling
            url={process.env.REACT_APP_RETOOL_URL}
          />
        </div>
      </div>
    );
  }
}

export default App;
